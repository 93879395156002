<template>
 <div>
   <div :id="chartName" class="chart-wrapper" :style="esize"></div>
   <Modal
      v-model="scModal"
      width="1280"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>风险点数据</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <list-with-page :tableParams="tableParams"></list-with-page>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_cancel">关闭</Button>
      </div>
    </Modal>
</div>
</template>
<script>
import { chartText } from "@/libs/mixins/chartSubText.js";
import { getExcel } from "@/libs/mixins/GetExcel.js";
import listWithPage from "@/components/Common/ListWithPage.vue";
let userToken = localStorage.getItem("current_user_token");
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3",
];
export default {
  name: "periodRiskSpread",
  components: {
    listWithPage,
  },
  data() {
    return {
      chartName: this.$options.name,
      title: "风险点分布图",
      chartData: [],
      chColor: "#c23531",
      scModal:false,
      updateCount:1,
      optionData:{
            optionList: [
                    /**{'option_title': "归属城市",'key_name':'city','value_list':[{'text':'上海市','value':'上海市','id':'city1','children':[{ 'text': '7号线', 'value': '7号线', 'id': 'sh7','color': '#ed6f00'},{ 'text': '11号线', 'value': '11号线', 'id': 'sh11' ,'color':'#871c2b'},{ 'text': '10号线', 'value': '10号线', 'id': 'sh10','color':'#c6afd4' },]},{ 'text': '长春市', 'value': '长春市', 'id': 'city2' ,'child':[{ 'text': '3号线', 'value': '3号线', 'id': 'chch3','color': '#ed6f09'},{ 'text': '1号线', 'value': '1号线', 'id': 'chch1' ,'color':'#871c9b'},{ 'text': '8号线', 'value': '8号线', 'id': 'chch8','color':'#c6afd8' },]},{ 'text': '南京市', 'value': '南京市', 'id': 'city3' },]},
                        {'option_title': "线路名称",'key_name':'line_name','value_list': []},
                        {'option_title': "严重等级",'key_name':'grade','value_list': [{ 'text': '可接受', 'value': '可接受', 'id': 'class3','stat':'' },{ 'text': '需重视', 'value': '需重视', 'id': 'class2' },{ 'text': '严重', 'value': '严重', 'id': 'class1' },{ 'text': '非常严重', 'value': '非常严重', 'id': 'class4' },{ 'text': '灾难性', 'value': '灾难性', 'id': 'class5','stat':'disable' },]},
                    **/
            ],
            selected_list: [],
            keyword: "",
        },
      tableParams: {
          Tpage: {
            currentPage: 1,
            currentSize: 20
          },
          totalItems: 0,
          noDataText: "无数据",
          loading: true,
          contentData: [],
          tableWidth: 1200,
          pageSizeOptions: [20, 50, 100, 200, 400],
          columnsFormat:  [
        {
          title: "填报公司",
          key: "company",
          width: 90,
          align: "center",
        },
        {
          title: "业务板块",
          key: "business_section",
          width: 100,
          align: "center",
        },
        {
          title: "部门",
          key: "department",
          align: "center",
          width: 100,
        },
        {
          title: "专业",
          key: "major",
          width: 70,
          align: "center",
        },
        {
          title: "风险点",
          key: "risk_content",
          width: 100,
          align: "center",
        },
        {
          title: "风险描述",
          key: "description",
          tooltip: "true",
          align: "center",
        },
        {
          title: "可能性L",
          key: "pl",
          width: 75,
          align: "center",
        },
        {
          title: "严重度C",
          key: "ic",
          width: 75,
          align: "center",
        },
        // {
        //   title: "风险值D",
        //   key: "pl",
        //   width: 75,
        //   align: "center",
        // },
        {
          title: "风险等级",
          key: "risk_level",
          width: 85,
          align: "center",
        },

        {
          title: "防范保障措施(事前预防）及应急保障措施（事后）",
          key: "solution",
          tooltip: "true",

          width: 210,
          align: "center",
        },
        {
          title: "责任岗位",
          key: "post",
          width: 120,
          align: "center",
        },
      ],
        },
    };
  },
  props: {
    choosedOptions: {
      type: Object,
      default() {
        return {
          selectedList: [],
          keyword: "",
        };
      },
    },
    esize: {
      type: Object,
      default() {
        return { height: "700px", width: "1000px" };
      },
    },
    drawAgain: {
      type: Number,
      default: 1,
    },
  },
  mixins: [chartText,getExcel],
  mounted() {
    let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];
  },
  watch: {
    drawAgain: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.get_data();
        }
      },
    },
  },
  computed: {
    // ctdata() {
    //   let temp = this.chartData;
    //   temp.sort((a, b) => {
    //     return a.value - b.value;
    //   });
    //   return temp;
    // },
    // majorList: function () {
    //   let tmp = [];
    //   for (let i of this.chartData) {
    //     if (tmp.indexOf(i[0]) === -1) {
    //       tmp.push(i[0]);
    //     }
    //   }
    //   return tmp;
    // },
    // degreeList: function () {
    //   let tmp = [];
    //   for (let i of this.chartData) {
    //     if (tmp.indexOf(i[1]) === -1) {
    //       tmp.push(i[1]);
    //     }
    //   }
    //   return tmp;
    // },
    // askData: function () {
    //   let tmp = [];
    //   for (let i of this.lineList) {
    //     tmp.push([2016, parseInt(i), 0]);
    //   }
    //   for (let i of this.totalData) {
    //     tmp.push([
    //       parseInt(i.year),
    //       parseInt(i.line_name),
    //       i.delayAmount[this.ivchoice],
    //     ]);
    //   }
    //   for (let i of this.lineList) {
    //     tmp.push([Math.max(...this.yearList) + 1, parseInt(i), 0]);
    //   }
    //   for (let i of this.lineList) {
    //     tmp.push([Math.max(...this.yearList) + 2, parseInt(i), 0]);
    //   }
    //   return tmp;
    // },
  },
  methods: {
    modal_cancel() {
      this.scModal = false;
    },
    handlePage(value) {
      this.Tpage = value;
      this.get_data();
    },
    get_list_data() {
      this.tableParams.loading = true;
      this.tableParams.NoDataText = "数据加载中...";
      this.$axios
        .post(this.$url + '/auth_interface', {
          page: this.tableParams.Tpage,
          askData:'periodRiskManage',
          choosedOptions:{selectedList:this.optionData.selected_list,keyword:this.optionData.keyword},
          userToken: userToken
        })
        .then(
          response => (
            (this.tableParams.contentData = response.data.res_record),
            (this.tableParams.totalItems = response.data.total_n),
            (this.tableParams.loading = false),
            (this.tableParams.NoDataText = "暂无数据")
          )
        );
    },
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/chart", {
          chartName: this.chartName,
          choosedOptions: this.choosedOptions,
          userToken: userToken,
        })
        .then((response) => {
          this.chartData = response.data.record;
          this.drawIt();
        });
    },
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById(this.chartName));
      // 绘制图表
      // let dataR = [
      //   this.chartData,
      // ]; 
      /*[
        [
          [2016, 2, 0],
 [2016, 3, 0],
[2016, 4, 0],
[2017, 2, 1],
 [2017, 3, 4],
[2017, 4, 1],
[2018, 2, 0],
[2018, 3, 0],
[2018, 4, 0],
[2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
[2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
 [2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
        ]
      ];*/
      let option = {
        backgroundColor: "#f7f7f7",
        title: {
          top: "10%",
          left: "center",
          text: "运营期间风险点数据库风险值分布图",
        },
        visualMap: {
            min: 0,
            max: 40,
            dimension: 3,
            orient: 'vertical',
            left: '4%',
            top: 'center',
            text: ['风险值', 'LOW'],
            calculable: true,
            inRange: {
                color: ['#f2c31a', '#960202']
            }
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            myToolsExcel:{
              paddingTop:'5px',
              show:true,
              top:'20px',
              title: '保存数据到EXCEL',
              icon:'image:///img/excel.png',
              onclick:function(){
                vm.excelGo(vm.chartData,['严重度','可能性','数量','风险值']);
                }
            },
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          top: "5%",
          right: "5%",
          data: [],
        },
        grid: {
          x: "18%",
          y: "25%",
          x2: "18%",
          y2: "18%",
          borderWidth: 10,
        },
        xAxis: {
          name:"严重度",
          type:'value',
          // data:[0,1,2,3,4,5,6,7,8,9,10],
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            alignWithLabel: true,
            show:true,
            lineStyle: {
            color: '#aaa',
            type: 'dashed',
            width: "1"
            }
          },
          // scale: true,
        },
        yAxis: {
          name:"可能性",
          type:'value',
          // data:[0,1,2,3,4,5,6,7,8,9,10],
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            alignWithLabel: true,
            show:true,
            lineStyle: {
            color: '#aaa',
            type: 'dashed',
            width: "1"
            }
          },
          // axisLabel: {
          //   formatter: function (s) {
          //     if (s === "0" || s === 0) {
          //       return "";
          //     } else {
          //       return s + "";
          //     }
          //   },
          // },
        },
        series: [
          {
            data: vm.chartData,
            type: "scatter",
            symbolSize: function (data) {
              return (data[2]+20)/3;
            },
            label: {
              emphasis: {
                show: true,
                formatter: function (param) {
                  return param.data[0] + "*"+param.data[1]+'共'+param.data[2]+'个';
                },
                position: "right",
                color:'#000',
              },
            },
            itemStyle: {
              color: vm.chColor,
              opacity: 0.8,
              shadowBlur: 1,
              shadowColor: "#e6e6e6",
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          },
        ],
      };
      myChart.setOption(option);
      myChart.on("click",function(params){
        vm.optionData.selected_list = [{'key_name':'ic','value':params.data[0].toString()},{'key_name':'pl','value':params.data[1].toString()}];
        vm.get_list_data();
        vm.scModal = true;
      });
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  border: 1px solid #eeeded;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
}
</style>