<template>
  <div>
    <data-view
      :columnsFormat="columnsFormat"
      :askData="op_name"
      @refreshDo="chartRefresh"
    >
      <template v-slot:default="dv_props">
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="ios-pie" />占比图
          </span>
          <div class="echarts-wrapper">
            <div class="single-chart-wrapper">
              <pr-level
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></pr-level>
            </div>
            <div class="single-chart-wrapper">
              <pr-business
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></pr-business>
            </div>
            <div class="single-chart-wrapper">
              <pr-company
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></pr-company>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="md-podium" />统计图
          </span>
          <div class="single-chart-wrapper">
            <pr-major
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></pr-major>
          </div>
          <div class="single-chart-wrapper">
            <pr-department
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></pr-department>
          </div>
          <div class="single-chart-wrapper">
            <pr-post
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></pr-post>
          </div>
          <!-- <div class="single-chart-wrapper">
            <risk-sort
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></risk-sort>
          </div>
          <div class="single-chart-wrapper">
            <correct-demand
              :choosedOptions="dv_props.choosedOptions"
              :drawAgain="updateCount"
              :esize="echartSize"
            ></correct-demand>
          </div> -->
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label" class="pane_label">
            <Icon type="ios-pie" />分布图
          </span>
          <div class="echarts-wrapper">
            <div class="single-chart-wrapper">
              <period-risk-spread
                :choosedOptions="dv_props.choosedOptions"
                :drawAgain="updateCount"
                :esize="echartSize"
              ></period-risk-spread>
            </div>
          </div>
        </el-tab-pane>
      </template>
      <risk-list-expand v-if="false"></risk-list-expand>
    </data-view>
  </div>
</template>
<script>
import dataView from "@/components/Common/DataView.vue";
import riskListExpand from "./RiskListExpand.vue";
import prLevel from "./Charts/PRLevel.vue";
import prCompany from "./Charts/PRCompany.vue";
import prBusiness from "./Charts/PRBusiness.vue";
import prMajor from "./Charts/PRMajor.vue";
import prDepartment from "./Charts/PRDepartment.vue";
import prPost from "./Charts/PRPost.vue";
import periodRiskSpread from "./Charts/PeriodRiskSpread.vue"

export default {
  name: "periodRiskList",
  props: [],
  components: {
    dataView,
    riskListExpand,
    prBusiness,
    prCompany,
    prLevel,
    prMajor,
    prDepartment,
    prPost,
    periodRiskSpread
    /*degreeLevel,
    correctStat,
    majorSystem,
    riskSort,
    correctDemand*/
  },
  data() {
    return {
      op_name: this.$options.name,
      imgUrl: {
        //分类说明: process.env.BASE_URL + "img/ins.jpg"
      },
      updateCount: 1,
      echartSize: { height: "700px", width: "1000px" },
      columnsFormat: [
        {
          type: "expand",
          width: 30,
          render: (h, params) => {
            return h(riskListExpand, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          title: "填报公司",
          key: "company",
          width: 90,
          align: "center",
        },
        {
          title: "业务板块",
          key: "business_section",
          width: 100,
          align: "center",
        },
        {
          title: "部门",
          key: "department",
          align: "center",
          width: 100,
        },
        {
          title: "专业",
          key: "major",
          width: 70,
          align: "center",
        },
        {
          title: "风险点",
          key: "risk_content",
          width: 100,
          align: "center",
        },
        {
          title: "风险描述",
          key: "description",
          tooltip: "true",
          align: "center",
        },
        {
          title: "可能性L",
          key: "pl",
          width: 75,
          align: "center",
        },
        {
          title: "严重度C",
          key: "ic",
          width: 75,
          align: "center",
        },
        // {
        //   title: "风险值D",
        //   key: "pl",
        //   width: 75,
        //   align: "center",
        // },
        {
          title: "风险等级",
          key: "risk_level",
          width: 85,
          align: "center",
        },

        {
          title: "防范保障措施(事前预防）及应急保障措施（事后）",
          key: "solution",
          tooltip: "true",

          width: 210,
          align: "center",
        },
        {
          title: "责任岗位",
          key: "post",
          width: 120,
          align: "center",
        },
      ],
    };
  },
  methods: {
    goNav() {
      location.href = "/risk_nav";
    },
    chartRefresh() {
      this.updateCount++;
    },
    selectedListTextFormat(value) {
      let temp = "";
      for (let i of value) {
        temp += "(" + i.text + ")";
      }
      return temp;
    },
  },
};
</script>
<style scoped>
.fix-link {
  position: fixed;
  right: 1%;
  top: 30%;
  height: 36px;
  background-color: #555;
  color: #eee;
  border-radius: 2px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 36px;
  opacity: 0.8;
}
.fix-link:hover {
  background-color: #333;
  color: #fff;
}
.single-chart-wrapper {
  margin-top: 10px;
}
</style>