<template>
    <div class="wrapper">
        <table class="expand_table">
            <tr v-show="row.res_person">
                <td class="expand-td-left">责任人: </td>
                <td class="expand-td-right">{{ row.res_person }}</td>
            </tr>
            <tr v-show="row.remark">
                <td class="expand-td-left">备注: </td>
                <td class="expand-td-right">{{ row.remark }}</td>
            </tr>
            
        </table>
    </div>
</template>
<script>
    export default {
        name: "riskListExpand",
        props: {
            row: Object
        }
    }
</script>
<style scoped>
    .wrapper{
        text-align: center;
    }
    .expand_table {
        margin: 0 auto;
        width:80%;
        border-collapse: collapse;
    }
    .expand-td-left{
        padding: 0 20px;
        width:150px;
        text-align: right;
        background-color: inherit;
        border: none;
        color:#888;
    }
    .expand-td-right{
        background-color: inherit;
        border: none;
        font-size: 14px;
    }
</style>